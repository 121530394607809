import { Component, TemplateRef } from '@angular/core';
import { ToastService } from '@theme/services/toast.service';

@Component({
  selector: 'ds365-toasts',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastsComponent {
  constructor(public toastService: ToastService) { }

  isTemplate(toast: any) { return toast.textOrTpl instanceof TemplateRef; }
}
