import { Component } from '@angular/core';
import { AppConfigurationsService } from '@core/services';
import { FooterService } from './footer.service';
import { saveAs } from 'file-saver';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ds365-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
  solution: string = ''
  constructor(
    public application: AppConfigurationsService,
    public service: FooterService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.solution = params['solution'] || 'ds365';
    });
  }

  downloadTerms(event) {
    event.preventDefault();
    this.service.downloadLastestTerms(this.solution).subscribe((res) => {
      saveAs(res, `${this.application.configs.solution_name} TCs.pdf`);
    });
  }
}
