import { Injectable } from '@angular/core';

import { AuthToken } from '../models/token';

@Injectable()
export class TokenStorage {
  protected key = 'ds365_token';

  constructor() {}

  get(): AuthToken {
    const token = localStorage.getItem(this.key);
    const authToken = new AuthToken(token);
    try {
      return authToken;
    } catch (e) {}
    return null;
  }

  set(token: AuthToken) {
    localStorage.setItem(this.key, token.getValue());
  }

  clear() {
    localStorage.removeItem(this.key);
  }
}
