import * as jwtDecode from 'jwt-decode';

export class AuthToken {
  protected payload: any = null;

  key = 'ds365_token';

  constructor(protected readonly token: any) {
    try {
      this.parsePayload();
    } catch (err) {
      localStorage.removeItem(this.key);
      // throw err;
    }
  }

  protected parsePayload(): void {
    if (!this.token) {
      throw new Error('Token not set.');
    }
    this.payload = jwtDecode(this.token);
  }

  getValue(): string {
    return this.token;
  }

  getProp(key: string): any {
    if (this.payload && this.payload.hasOwnProperty(key)) {
      return this.payload[key];
    }
    return null;
  }

  getPayload(): any {
    return this.payload;
  }

  isValid(): boolean {
    const currentTime = new Date().getTime() / 1000;
    return this.payload && this.payload.hasOwnProperty('exp') && currentTime < this.payload.exp;
  }
}
