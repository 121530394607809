import { Component, Inject } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { CONFIRM_BOX_PARAMS } from './confirm-box.token';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { find } from 'lodash';

@Component({
  selector: 'ds365-custom-confirm-box',
  template: `
    <div class="container mt-100">
      <div class="row">
        <div class="col confirmbox">
          <h4 class="text-white text-center multiline-text" [innerHTML]="params.text"></h4>
          <div class="row mt-5">
            <div class="col-6">
            <a *ngIf="params.url" [href]="params.url" target="_blank">{{ params.linkText }}</a>
            <label for="ta-remark">SAP Ship To<span class="text-danger"> *</span></label>
              <ds365-input
              [id]="'sapReferenceNumber'"
              [group]="form"
              controlName="sapReferenceNumber"
              [maxLength]="64"
              placeholder="Enter SAP Ship To"
            >
            </ds365-input>
            <small class="form-text text-light">&nbsp;</small>
            </div>
            <div class="col-6">
            <ds365-checkbox
            [id]="'autoApprove'"
            [group]="form"
            controlName="autoApprove"
          >
            Auto-approve its employees
          </ds365-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
            <ds365-select
                  [id]="'halRegion'"
                  [fg]="form"
                  [items]="halRegions"
                  [control]="form.get('halRegion')"
                  noneSelectedText="Select HAL region from the list"
                  optString="name"
                  optKey="code"
                >
                  <option disabled  selected value firstOption>Select HAL region from the list</option>
                  <ng-container label>HAL Region
                  <a class="ml-2 nav-link d-inline p-0 cursor-pointer">
                    <span ngbTooltip="HAL region administers company's pricing, product availability, CoS and invoicing.">
                      <ds365-icon svgName="information" svgSize="16" status='white'></ds365-icon>
                    </span>
                  </a>
                  </ng-container>
                </ds365-select>
            </div>
          </div>
          <a *ngIf="params.url" [href]="params.url" target="_blank">{{ params.linkText }}</a>
          <div class="remark-container">
            <label for="ta-remark">Remarks<span class="text-danger"> *</span></label>
            <ds365-text-area
              id="ta-remark"
              [group]="form"
              controlName="remark"
              requiredMsg="Remark is required"
              [maxLength]="1024"
              placeholder="{{params.placeholder}}"
              [rows]="4">
              <ng-container note><i>{{ params.helpText }}</i></ng-container>
            </ds365-text-area>
          </div>
          <div class="clearfix"></div>
          <div class="pt-2">
            <button
              *ngIf="params.acceptText"
              [disabled]="!form.valid"
              ds365Button
              [space]="true"
              status="secondary"
              id="confirm-accepts"
              [fullWidth]="true"
              (click)="accepted()"
            >
              {{ params.acceptText }}
            </button>
            <button ds365Button status="secondary" id="confirm-rejects" [fullWidth]="true" (click)="rejected()">
              {{ params.rejectText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      .confirmbox {
        background: #6a1213;
        border: 2px solid #d43e24;
        border-radius: 10px;
      }
      button {
        max-width: 250px !important;
        margin: 1rem auto !important;
      }
      .multiline-text {
        white-space: pre-line;
      }
      .form-control {
        width: 100%;
        min-height: 150px;
      }
      .mt-100 {
        margin-top: 100px;
      }
      textarea {
        border: 0px;
        width: 100%;
        height: 100%;
      }
      .remark-container {
        min-width: 600px;
        text-align: left;
      }
      .remark-container label {
        float: none;
      }
      textarea:focus,
      textarea:active {
        border: 0px;
        outline: none;
      }
      
      ::ng-deep .form-control textarea {
        color: black !important;
      }
      @media only screen and (max-width: 640px) and (min-width: 320px) {
        .multiline-text {
          font-size: 14px !important;
          margin-top: 100px;
        }
      }
      @media (min-width: 768px) {
        .form-control {
          min-width: 600px;
        }
      }
    `,
  ],
})
export class CustomConfirmBoxComponent {
  public form: FormGroup;
  public halRegions: any;
  constructor(private overlayRef: OverlayRef, @Inject(CONFIRM_BOX_PARAMS) public params: any, private fb: FormBuilder) {
    this.form = this.fb.group({
      sapReferenceNumber: [null, [Validators.required]],
      autoApprove: true,
      remark: [null, [Validators.required]],
      halRegion: ['', Validators.required],
    });
    this.halRegions = this.params.halRegions;
    this.halRegions.forEach(item => {
      item.selected = false;
    });
    if (this.params.halRegion) {
      find(this.halRegions, { code: this.params.halRegion }).selected = true;
    }
  }

  accepted() {
    if (this.params.isInput) {
      const output = {
        remark: this.form.value.remark,
        provided_license: 'yes',  // Provide 5E002 License
        expiry_date: null,
        approval_url: null,
        sap_reference_number: this.form.value.sapReferenceNumber,
        auto_approve: this.form.value.autoApprove,
        hal_region: this.form.value.halRegion,
      };
      this.params.action.next(output);
    } else {
      this.params.action.next(true);
    }
    this.overlayRef.dispose();
  }

  rejected() {
    this.params.action.next(false);
    this.overlayRef.dispose();
  }
}
