import { Injectable, TemplateRef } from '@angular/core';

type ToastOptions = {
  id?: number,
  classname?: string,
  autohide?: boolean,
  delay?: number,
  icon?: boolean,
  iconname?: string,
  dismissable?: boolean
};
@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  getId(): number {
    return Date.now();
  }

  defaultOptions(type: string): ToastOptions {
    return {
      id: this.getId(),
      classname: 'toast-' + type,
      autohide: true,
      delay: 5000,
      icon: true,
      iconname: 'toast-' + type,
      dismissable: true
    }
  }

  show(textOrTpl: string | TemplateRef<any>, options: ToastOptions = {}): Number | undefined {
    const defaults = this.defaultOptions('');
    this.toasts.push({ textOrTpl, ...defaults, ...options });
    return defaults.id;
  }

  success(textOrTpl: string | TemplateRef<any>, options: ToastOptions = {}): Number | undefined {
    const defaults = this.defaultOptions('success');
    this.toasts.push({ textOrTpl, ...defaults, ...options });
    return defaults.id;
  }

  error(textOrTpl: string | TemplateRef<any>, options: ToastOptions = {}): Number | undefined {
    const defaults = this.defaultOptions('error');
    this.toasts.push({ textOrTpl, ...defaults, ...options });
    return defaults.id;
  }

  warn(textOrTpl: string | TemplateRef<any>, options: ToastOptions = {}): Number | undefined {
    const defaults = this.defaultOptions('warning');
    this.toasts.push({ textOrTpl, ...defaults, ...options });
    return defaults.id;
  }

  info(textOrTpl: string | TemplateRef<any>, options: ToastOptions = {}): Number | undefined {
    const defaults = this.defaultOptions('info');
    this.toasts.push({ textOrTpl, ...defaults, ...options });
    return defaults.id;
  }

  remove(id: number) {
    this.toasts = this.toasts.filter(t => t.id != id);
  }

  removeAll() {
    this.toasts = [];
  }

  active(id: number) {
    return this.toasts.findIndex(t => t.id === id) !== -1;
  }
}
