import { Component, Inject } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { CONFIRM_BOX_PARAMS } from './confirm-box.token';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ds365-confirm-box',
  template: `
    <div class="container mt-100">
      <div class="row">
        <div class="col text-center confirmbox">
          <h4 class="text-white multiline-text" [ngClass]="params.class" [innerHTML]="params.text"></h4>
          <a *ngIf="params.url" [href]="params.url" target="_blank">{{ params.linkText }}</a>
          <div class="remark-container" *ngIf="params.isInput">
            <label for="ta-remark">Remarks<span class="text-danger">*</span></label>
            <ds365-text-area
              id="ta-remark"
              [group]="form"
              controlName="remark"
              requiredMsg="Remark is required"
              [maxLength]="1024"
              placeholder="{{params.placeholder}}"
              [rows]="4">
              <ng-container note><i>{{ params.helpText }}</i></ng-container>
            </ds365-text-area>
          </div>
          <div *ngIf="params.checkBox" class="custom-control custom-control-inline custom-checkbox text-white mt-2">
            <input class="custom-control-input" type="checkbox" id="cbox-check" [value]="true"
            (change)="params.checBoxChange.next($event)" />
            <label class="custom-control-label" for="cbox-check">{{ params.checkBox }}</label>
          </div>
          <div class="clearfix"></div>
          <div class="">
            <button
              *ngIf="params.acceptText"
              [disabled]="params.isInput && !form.valid"
              ds365Button
              [space]="true"
              status="primary"
              id="confirm-accepts"
              [fullWidth]="true"
              (click)="accepted()"
            >
              {{ params.acceptText }}
            </button>
            <button ds365Button status="white" [bordered]="true" id="confirm-rejects" [fullWidth]="true" (click)="rejected()">
              {{ params.rejectText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      .confirmbox {
        background: #6a1213;
        border: 2px solid #d43e24;
        border-radius: 10px;
      }
      button {
        max-width: 250px !important;
        margin: 1rem auto !important;
      }
      .multiline-text {
        white-space: pre-line;
        line-height: 200%;
      }
      .form-control {
        min-width: 80vw;
        min-height: 150px;
      }
      .mt-100 {
        margin-top: 100px;
      }
      textarea {
        border: 0px;
        width: 100%;
        height: 100%;
      }
      .remark-container {
        min-width: 600px;
        text-align: left;
      }
      .remark-container label {
        float: none;
      }
      textarea:focus,
      textarea:active {
        border: 0px;
        outline: none;
      }
      @media only screen and (max-width: 640px) and (min-width: 320px) {
        .multiline-text {
          font-size: 14px !important;
          margin-top: 100px;
        }
      }
      @media (min-width: 768px) {
        .form-control {
          min-width: 600px;
        }
      }

      ::ng-deep .form-control textarea {
        color: black !important;
      }
      
    `,
  ],
})
export class ConfirmBoxComponent {
  public form: FormGroup;
  
  constructor(private overlayRef: OverlayRef, @Inject(CONFIRM_BOX_PARAMS) public params: any, private fb: FormBuilder) {
    this.form = this.fb.group({
      remark: [null, [Validators.required]],
    });
  }

  accepted() {
    if (this.params.isInput) {
      this.params.action.next(this.form.value.remark);
    } else {
      this.params.action.next(true);
    }
    this.overlayRef.dispose();
  }

  rejected() {
    this.params.action.next(false);
    this.overlayRef.dispose();
  }
}
