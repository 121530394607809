import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard, NoAuthGuard } from '@core/auth/guards';
import { ApplicationGuard } from '@core/auth/guards/application.guard';
import { RedirectGuard } from '@core/auth/guards/landingpage-redirect.guard';
import { RequestInterceptor } from '@core/auth/interceptors/request-interceptor';
import { CoreModule } from '@core/core.module';
import { AlertModule } from '@theme/layouts/alert/alert.module';
import { ThemeModule } from '@theme/theme.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const keycloakService = new KeycloakService();

const MODULES = [CoreModule.forRoot(), ThemeModule.forRoot(), AlertModule, AppRoutingModule, KeycloakAngularModule];

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserAnimationsModule, ...MODULES],
  bootstrap: [AppComponent],
  providers: [
    AuthGuard,
    RedirectGuard,
    NoAuthGuard,
    ApplicationGuard,
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  ],
})
export class AppModule {}
