import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, NoAuthGuard } from '@core/auth/guards';
// import { RedirectGuard } from '@core/auth/guards/landingpage-redirect.guard';
// import { BlankComponent } from './modules/settings/blank.component';
// import { UnauthorisedComponent } from './modules/settings/unauthorised/unauthorised.component';

const routes: Routes = [
  // {
  //   path: 'auth',
  //   component: BlankComponent,
  //   children: [
  //     {
  //       path: 'unauthorised',
  //       component: UnauthorisedComponent, // TODO: It should be lazy loaded as well
  //       data: { title: 'Unauthorized' }
  //     },
  //     {
  //       path: 'terms',
  //       loadChildren: () => import('./modules/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
  //     }
  //   ]
  // },
  {
    path: 'auth',
    // canActivate: [NoAuthGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'welcome',
    canActivate: [AuthGuard],
    data: {roles: ['sample-user']},
    loadChildren: () => import('./modules/welcome/welcome.module').then(m => m.WelcomeModule),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: '**',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
