import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserService } from '@core/services/user.service';
import { WelcomeService } from 'app/modules/welcome/welcome.service';
import { forkJoin } from 'rxjs';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
  constructor(protected router: Router, protected keycloakAngular: KeycloakService, private authService: AuthService) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.keycloakAngular
      .updateToken(120)
      .then(() => {
        if (!this.authenticated) {
          this.keycloakAngular.login().catch(e => console.error(e));
          return false;
        }
        const requiredRoles: string[] = route.data.roles;
        if (!requiredRoles || requiredRoles.length === 0) {
          return true;
        }
        if (!this.roles || this.roles.length === 0) {
          return false;
        }
        if(requiredRoles.some(role => this.roles.indexOf(role) > -1)) {
          return true;
        }
        
        this.router.navigateByUrl('auth/unauthorized');
      })
      .catch(err => {
        this.keycloakAngular.login().catch(e => console.error(e));
        return false;
      });
  }
}
