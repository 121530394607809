import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@core/auth';
import { switchMap, shareReplay, map, pluck, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private cache$: any = {};

  public isUserApproved: boolean;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public searchUserByKeyword(keyword: string, status: string): Observable<any[]> {
    if (!keyword.trim()) {
      return of([]);
    }
    return this.http.get<any[]>(`/users/search?keyword=${keyword}&statusFilter=${status}`);
  }

  public isLeaderOfProject(project) {
    return this.authService.getToken().pipe(
      switchMap((data: any) => {
        if (data && data.payload) {
          const username = data.payload.preferred_username.toUpperCase();
          const user = project.project_user_association_v.filter(
            item =>
              item.username.toString().toUpperCase() === username && item.user_type.toString().toUpperCase() === 'LEAD'
          );
          return of(!!user.length);
        }
      })
    );
  }

  public getProfile(): Observable<any> {
    if (!this.cache$.getProfile) {
      this.cache$.getProfile = this.http.get(`users/profile`).pipe(shareReplay(1));
    }
    return this.cache$.getProfile;
  }

  public getAccountManagers() {
    return this.http.get(`users/managers`);
  }

  public getApprovedUsers() {
    return this.http.get(`users/?status=approved`);
  }

  public getTerms() {
    return this.http.get(`/terms/`).pipe(map((data: any) => data.terms));
  }

  public termsAccepted() {
    return this.http.get(`/users/terms/accepted`, { observe: 'response' })
      .pipe(
        map((response:any) => {
          return { isError: !response.body, status: response.statusCode || response.status };
        }),
        catchError((error) => {
          return of({ isError: true, status: error.statusCode || error.status });
        })
      );
  }

  public acceptUpdatedTerms(terms) {
    return this.http.post(`/users/terms`, terms);
  }

  public getUserAttribute(key){
    return this.http.get(`users/attributes/${key}`)
  }

  public saveUserAttribute(payload){
    return this.http.post(`users/attributes`,payload);
  }
  
  public getUserOktaProfile(){
    return this.http.get(`users/okta`)
  }

  public joinSampleApp(){
    return this.http.get(`users/okta/solution/join/sample`)
  }
  public company(): Observable<any> {
    if (!this.cache$.company) {
      this.cache$.company = this.getProfile().pipe(switchMap((p) => {
        return this.http.get(`/companies/${p.company_id}`).pipe(shareReplay(1));
      }))
    }
    return this.cache$.company;
  }

  public solutionRequest(payload: any) {
    return this.http.post(`/users/bpm/requests/solution`, payload);
  }

  public checkTerms() {
    return this.http.get(`/users/terms/accepted`);
  }

}
