import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppConfigurationsService } from '@core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '..';
import { AuthToken } from '../models/token';

@Injectable()
export class ApplicationGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private config: AppConfigurationsService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getToken()
      .pipe(map((token: AuthToken | null): boolean => {
        const roles = (token as AuthToken).getProp(`resource_access.${this.config.appName}.roles`);
        if (!roles || roles.indexOf(`${this.config.appName}-user`) === -1) {
          this.router.navigate(['/auth/unauthorised']);
          return false;
        }
        return true;
      }));
  }
}
