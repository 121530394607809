import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/auth';
import { AppConfigurations } from '@core/models/app.configurations';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { interval, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AppConfigurationsService {
  private configurations: AppConfigurations;
  userDetails: KeycloakProfile;

  constructor(private http: HttpClient, private authService: AuthService, private keycloakService: KeycloakService) {
  }

  get configs(): AppConfigurations {
    return this.configurations;
  }
  public get appName() {
    return 'sample'
  }

  load() {
    const solutionName = this.getQueryParameterByName('solution') || 'default'
    const promise = this.http
      .get(`/users/configurations/clientSideConfigurations/${solutionName}`)
      .toPromise();

    promise.then((configs: AppConfigurations) => {
      this.configurations = configs
    });
    return promise;
  }

  initKeycloak() {
    const promise = this.keycloakService.init();
    return promise;
  }

  currentUser() {
    const promise = this.keycloakService.isLoggedIn().then((loggedIn: boolean) => {
      if (loggedIn) {
        this.keycloakService.getToken().then(token => this.authService.setToken(token));
        interval(180000).subscribe(val => {
          console.log('[KEYCLOAK]', 'Update Token Triggered');
          this.keycloakService.updateToken(120).catch(e => {
            console.log('[KEYCLOK]', 'Update Token Failed');
          });
        });
      }
    });
    return promise;
  }
  getQueryParameterByName(name) {
    // We are using this method because injecting activated Route here causes cyclic dependency.
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}
}
