import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '../icon/icon.module';
import { CommandLinkItemComponent } from './dropdown-item/command-link-item/command-link-item.component';
import { DropdownItemTemplateComponent } from './dropdown-item/dropdown-item-template/dropdown-item-template.component';
import { InsideLinkItemComponent } from './dropdown-item/inside-link-item/inside-link-item.component';
import { OutsideLinkItemComponent } from './dropdown-item/outside-link-item/outside-link-item.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NavbarsComponent } from './navbars.component';
import { CoreModule } from '@core/core.module';

@NgModule({
  declarations: [
    NavbarsComponent,
    DropdownComponent,
    InsideLinkItemComponent,
    OutsideLinkItemComponent,
    CommandLinkItemComponent,
    DropdownItemTemplateComponent,
  ],
  imports: [CommonModule, CoreModule, IconModule, NgbModule, RouterModule, NgbTooltipModule],
  exports: [NavbarsComponent],
})
export class NavbarsModule {}
